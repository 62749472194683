import { ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { config } from 'src/config'
import { TReduxState, store } from 'src/redux/store'
import { privateClient } from 'src/utils/clients'
// import { LoginMethods } from 'src/tracking/gtm/enums'
import { getAuthSource, getAuthLoginCase, getSelfUrl, getUtmParams, getProductFromPath } from 'src/utils/auth'
import { ActionTypes } from './enums'
import {
  IAuthUser,
  IAuthMetaData,
  TLoginAction,
  TLoginSuccessAction,
  TNicknameUpdateAction,
  TNicknameUpdateSuccessAction,
  TNicknameUpdateFailureAction,
  TLogoutAction,
  TLoginCheckStartAction,
  TLoginCheckFinishAction,
  TLoginOptions,
} from './types'
import { getIsLoggedIn } from './selectors'
// import { trackUserLogin } from 'src/tracking/gtm/actions'
// import { trackError } from 'src/tracking/sentry'
import { checkBackendError, isBackendError, isHttpError } from 'src/utils/errors'
import { toasty } from 'src/components/toast/toasty'
// import { getCookie } from 'src/utils/cookie'
// import { getProductSeasonId } from 'src/api/product/selectors'

export const login: ActionCreator<TLoginAction> = (method = '', { returnUrl = '' }: TLoginOptions = {}) => {
  const selfUrl = returnUrl.match(/:\/\//) ? returnUrl : getSelfUrl() + returnUrl

  const productId = getProductFromPath()
  const loginCase = getAuthLoginCase()
  const source = getAuthSource()
  const utmParams = getUtmParams()
  // const gacid = getCookie('_ga')
  // const ga4_sid = 'MLMFLVGTVS'
  // const ga4_value = getCookie('_ga_MLMFLVGTVS')
  // const seasonId = getProductSeasonId(store.getState())

  // trackUserLogin(method, false)

  const url = `${config.api.backend.url}/auth/begin
?loginRedirect=${encodeURIComponent(selfUrl)}
&loginCase=${loginCase}
&source=${source}_${productId}_${method}
&utmParams=${utmParams}
`
  // &gacid=${gacid}
  // &ga4_sid=${ga4_sid}
  // &ga4_value=${ga4_value}

  if (window.top) {
    window.top.location.href = url
  } else {
    window.location.href = url
  }

  return {
    type: ActionTypes.AUTH_LOGIN,
  }
}

const loginSuccess =
  (payload: {
    user: IAuthUser
    metaData: IAuthMetaData | undefined
  }): ThunkAction<void, TReduxState, unknown, TLoginSuccessAction> =>
  async (dispatch) => {
    dispatch({
      type: ActionTypes.AUTH_LOGIN_SUCCESS,
      payload,
    })
  }

export const logout: ActionCreator<TLogoutAction> = ({ returnUrl = '' }: { returnUrl?: string }) => {
  const selfUrl = returnUrl.match(/:\/\//) ? returnUrl : getSelfUrl() + returnUrl

  const url = `${config.api.backend.url}/auth/logout?logoutRedirect=${encodeURIComponent(selfUrl)}`

  window.location.href = url

  return {
    type: ActionTypes.AUTH_LOGOUT,
  }
}

export const checkBackendAuth =
  (
    method: string | null,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TLoginSuccessAction | TLogoutAction | TLoginCheckStartAction | TLoginCheckFinishAction
  > =>
  async (dispatch, getState) => {
    const url = `${config.api.backend.url}/session`
    let res
    dispatch({
      type: ActionTypes.AUTH_CHECK_START,
    })

    try {
      res = await privateClient.get<IAuthUser>(url)

      if (isBackendError(res) && res.response?.status === 401) {
        throw new Error('Unauthorized') // Successful request with customized BE error message needs to be handled separately.
      }
    } catch (err) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    // Offline? User should not be logged out.
    if (!res) {
      return
    }

    const isLoggedIn = Boolean(res.data?.sub)
    const isStoreLoggedIn = getIsLoggedIn(store.getState())

    // Login the redux store and update session data
    if (isLoggedIn) {
      dispatch(loginSuccess({ user: res.data, metaData: undefined }))

      // if (method) {
      // const userSub = getUserSub(getState())
      // trackUserLogin(method, true, userSub)
      // }
    }

    // Logout the redux store, if it isn't logged in
    if (!isLoggedIn && isStoreLoggedIn) {
      dispatch({
        type: ActionTypes.AUTH_LOGOUT,
      })
    }

    dispatch({
      type: ActionTypes.AUTH_CHECK_FINISH,
    })
  }

export const updateNickname =
  (
    nickname: string,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TNicknameUpdateAction | TNicknameUpdateSuccessAction | TNicknameUpdateFailureAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/user/nickname`
    let res
    dispatch({
      type: ActionTypes.AUTH_UPDATE_NICKNAME,
    })
    try {
      res = await privateClient.put<{ nickname: string }>(url, { nickname })

      checkBackendError(res, 'Aktualizacja nicka nie powiodła się')

      dispatch({
        type: ActionTypes.AUTH_UPDATE_NICKNAME_SUCCESS,
        payload: { nickname },
      })

      toasty('Nick zaktualizowany', { type: 'success' })
    } catch (err) {
      const getErrorText = (message: string, status?: number) => {
        if (status === 422) {
          return 'Nick powinien mieć od 2 do 30 znaków i nie powinien zawierać znaków specjalnych.'
        }

        switch (message) {
          case 'already_taken':
            return 'Ten nick już istnieje. Proszę wybrać inny.'
          case 'not_allowed':
          case 'Not allowed':
            return 'Ten nick jest niedozwolony. Proszę wybrać inny.'

          default:
            return message
        }
      }
      if (isHttpError(err)) {
        dispatch({
          type: ActionTypes.AUTH_UPDATE_NICKNAME_FAILURE,
          payload: { ...err, message: getErrorText(err.message, err.status) },
        })
      }
    }
  }

export const resetNicknameError = () => ({
  type: ActionTypes.AUTH_RESET_NICKNAME_ERROR,
})

export const showNicknameModal = () => ({
  type: ActionTypes.AUTH_SHOW_REQ_MODAL,
})

export const hideNicknameModal = () => ({
  type: ActionTypes.AUTH_HIDE_REQ_MODAL,
})
