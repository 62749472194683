import { ThunkAction } from 'redux-thunk'
import { TReduxState } from 'src/redux/store'
import { privateClient } from 'src/utils/clients'

import { config } from 'src/config'
import { buildUrl, getOptionalParameter } from 'src/api/utils/buildUrl'
import { ActionTypes } from './enums'
import {
  Actions,
  TCheckAnswerPayload,
  TCheckCrosswordAction,
  TCheckCrosswordFailureAction,
  TCheckCrosswordPayload,
  TCheckCrosswordResponse,
  TCheckCrosswordSuccessAction,
  TCheckWordAction,
  TCheckWordFailureAction,
  TCheckWordPayload,
  TCheckWordSuccessAction,
  TCleanCheckCrosswordStates,
  TCleanCheckWordStates,
  TCleanFetchCrosswordStateStates,
  TCleanUpdateCrosswordStateStates,
  TClearCorrectIncorrectWords,
  TClearFromIncorrectWords,
  TClearIncorrectWord,
  TCrosswordState,
  TCrosswords,
  TFetchCrosswordStateAction,
  TFetchCrosswordStateFailureAction,
  TFetchCrosswordStateSuccessAction,
  TFetchCrosswordsPayload,
  TSetCrosswordsArchiveFilterAction,
  TUpdateCrosswordStateAction,
  TUpdateCrosswordStateFailureAction,
  TUpdateCrosswordStateSuccessAction,
} from './types'
import { checkBackendError, extractBackendErrorMessage, getReduxErrorObject, isBackendError } from 'src/utils/errors'
import { DateFormat, formatDate } from 'src/utils/date/formatDate'
import { toasty } from 'src/components/toast/toasty'
import { TSelectOption } from 'src/components/Select/types'

export const TFetchLatestCrossword =
  (productId: string): ThunkAction<void, TReduxState, unknown, Actions> =>
  async (dispatch) => {
    try {
      dispatch({
        type: ActionTypes.FETCH_LATEST_CROSSWORD,
      })

      const today = formatDate(new Date(), DateFormat.YEAR_MONTH_DAY)

      const url = buildUrl(`${config.api.backend.url}/crosswords?productId=${productId}&limit=1&filter=${today}`)
      const res = await privateClient.get<TCrosswords>(url)

      if (res.status !== 200) {
        throw new Error('Failed to fetch crosswords')
      }

      dispatch({
        type: ActionTypes.FETCH_LATEST_CROSSWORD_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_LATEST_CROSSWORD_FAILURE,
        payload: { error: err },
      })
    }
  }

export const fetchCrosswords =
  (requestPayload: TFetchCrosswordsPayload): ThunkAction<void, TReduxState, unknown, Actions> =>
  async (dispatch) => {
    const { productId, filter, offset, limit, isAcrhive } = requestPayload

    try {
      dispatch({
        type: ActionTypes.FETCH_CROSSWORDS,
      })
      const url = buildUrl(
        `${config.api.backend.url}/crosswords?productId=${productId}${getOptionalParameter({
          filter,
        })}${getOptionalParameter({ offset })}${getOptionalParameter({ limit })}`,
      )
      const res = await privateClient.get<TCrosswords>(url)

      if (res.status !== 200) {
        throw new Error('Failed to fetch crosswords')
      }

      dispatch({
        type: isAcrhive ? ActionTypes.FETCH_CROSSWORDS_ARCHIVE_SUCCESS : ActionTypes.FETCH_CROSSWORDS_SUCCESS,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_CROSSWORDS_FAILURE,
        payload: { error: err },
      })
    }
  }

export const setArchiveFilter = (filter: TSelectOption): TSetCrosswordsArchiveFilterAction => ({
  type: ActionTypes.SET_CROSSWORDS_ARCHIVE_FILTER,
  payload: filter,
})

export const fetchCrosswordState =
  (
    id: number,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TFetchCrosswordStateAction | TFetchCrosswordStateSuccessAction | TFetchCrosswordStateFailureAction
  > =>
  async (dispatch) => {
    const url = `${config.api.backend.url}/states/${id}`

    try {
      dispatch({
        type: ActionTypes.FETCH_CROSSWORD_STATE,
      })

      const res = await privateClient.get(url)

      checkBackendError(res, "State wasn't fetched")

      dispatch({
        type: ActionTypes.FETCH_CROSSWORD_STATE_SUCCESS,
        payload: { id, ...res.data },
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.FETCH_CROSSWORD_STATE_FAILURE,
        payload: getReduxErrorObject(err),
      })
    }
  }

export const cleanFetchCrosswordStateStates = (): TCleanFetchCrosswordStateStates => ({
  type: ActionTypes.CLEAN_FETCH_CROSSWORD_STATE_STATES,
})

export const updateCrosswordState =
  (
    requestPayload: TCrosswordState,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TUpdateCrosswordStateAction | TUpdateCrosswordStateSuccessAction | TUpdateCrosswordStateFailureAction
  > =>
  async (dispatch) => {
    const { id, revision = -1, state, isSolved } = requestPayload
    const url = `${config.api.backend.url}/states/${id}`

    try {
      dispatch({
        type: ActionTypes.UPDATE_CROSSWORD_STATE,
      })

      const res = await privateClient.put(url, { previousRevision: revision, state })

      checkBackendError(res, "State wasn't updated")

      dispatch({
        type: ActionTypes.UPDATE_CROSSWORD_STATE_SUCCESS,
        payload: { id, revision: revision + 1, state, isSolved },
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.UPDATE_CROSSWORD_STATE_FAILURE,
        payload: getReduxErrorObject(err),
      })
      if (isBackendError(err) && extractBackendErrorMessage(err) === 'revision_conflict') {
        dispatch(fetchCrosswordState(id))
        toasty('Crossword state updated')
      }
    }
  }

export const cleanUpdateCrosswordStateStates = (): TCleanUpdateCrosswordStateStates => ({
  type: ActionTypes.CLEAN_UPDATE_CROSSWORD_STATE_STATES,
})

export const checkWord =
  (
    requestPayload: TCheckWordPayload,
  ): ThunkAction<void, TReduxState, unknown, TCheckWordAction | TCheckWordSuccessAction | TCheckWordFailureAction> =>
  async (dispatch) => {
    const { id, direction, across, down, number, value, revision } = requestPayload

    const url = `${config.api.backend.url}/crosswords/${id}/check-word`

    try {
      dispatch({
        type: ActionTypes.CHECK_WORD,
      })

      const payload: TCheckAnswerPayload = { value }

      if (direction === 'across') {
        payload.across = parseInt(number)
      }

      if (direction === 'down') {
        payload.down = parseInt(number)
      }

      const res = await privateClient.post<{ correct: boolean }>(url, { revision, ...payload })

      checkBackendError(res, 'Check word failed!')

      dispatch({
        type: ActionTypes.CHECK_WORD_SUCCESS,
        payload: {
          correct: res.data.correct,
          direction: direction,
          across: across ? across : 0,
          down: down ? down : 0,
          number,
        },
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.CHECK_WORD_FAILURE,
        payload: getReduxErrorObject(err),
      })
      toasty(err instanceof Error ? err.message : 'Unknown error', { type: 'error' })
    }
  }

export const cleanCheckWordSeasonStates = (): TCleanCheckWordStates => ({
  type: ActionTypes.CLEAN_CHECK_WORD_STATES,
})

export const checkCrossword =
  (
    requestPayload: TCheckCrosswordPayload,
  ): ThunkAction<
    void,
    TReduxState,
    unknown,
    TCheckCrosswordAction | TCheckCrosswordSuccessAction | TCheckCrosswordFailureAction
  > =>
  async (dispatch) => {
    const { id, revision, guesses } = requestPayload
    const url = `${config.api.backend.url}/crosswords/${id}/check-crossword`

    const answers = guesses.map((guess) => {
      const payload: TCheckAnswerPayload = {
        value: guess.value,
      }

      if (guess.direction === 'across') {
        payload.across = parseInt(guess.number)
      }

      if (guess.direction === 'down') {
        payload.down = parseInt(guess.number)
      }
      return payload
    })

    try {
      dispatch({
        type: ActionTypes.CHECK_CROSSWORD,
      })

      const res = await privateClient.post<TCheckCrosswordResponse>(url, { revision, answers })

      checkBackendError(res, 'Check crossword failed')

      dispatch({
        type: ActionTypes.CHECK_CROSSWORD_SUCCESS,
        payload: {
          guesses,
          ...res.data,
          id,
        },
      })
    } catch (err) {
      dispatch({
        type: ActionTypes.CHECK_CROSSWORD_FAILURE,
        payload: getReduxErrorObject(err),
      })
      toasty(err instanceof Error ? err.message : 'Unknown error', { type: 'error' })
    }
  }

export const cleanCheckCrosswordStates = (): TCleanCheckCrosswordStates => ({
  type: ActionTypes.CLEAN_CHECK_CROSSWORD_STATES,
})

export const clearCorrectIncorrectWords = (): TClearCorrectIncorrectWords => ({
  type: ActionTypes.CLEAR_CORRECT_INCORRECT_WORDS,
})

export const clearIncorrectWords = (): TClearIncorrectWord => ({
  type: ActionTypes.CLEAR_INCORRECT_WORDS,
})

export const clearFromIncorrectWords = (number: string): TClearFromIncorrectWords => ({
  type: ActionTypes.CLEAR_FROM_INCORRECT_WORDS,
  payload: { number },
})
